import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '.'
import { AttributeFilterOptions } from '@/types';

interface DynamicFilterOptionsPayload {
    id: string;
    options: AttributeFilterOptions;
}

// Define a type for the slice state
interface SearchState {
    showAdvancedSearch: boolean;
    dynamicFiltersCache: Record<string, AttributeFilterOptions>;
}

// Define the initial state using that type
const initialState: SearchState = {
    showAdvancedSearch: false,
    dynamicFiltersCache: {},
};

const searchSlice = createSlice({
    name: 'search',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        toggleAdvancedSearch: (state) => {
            state.showAdvancedSearch = !state.showAdvancedSearch;
        },
        // Use the PayloadAction type to declare the contents of `action.payload`
        setAdvancedSearch: (state, action: PayloadAction<boolean>) => {
            state.showAdvancedSearch = action.payload;
        },
        setDynamicFilterOptions: (state, action: PayloadAction<DynamicFilterOptionsPayload>) => {
            state.dynamicFiltersCache[action.payload.id] = action.payload.options;
        },
    },
});

export const { toggleAdvancedSearch, setAdvancedSearch, setDynamicFilterOptions } = searchSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectSearch = (state: RootState) => state.search;

export default searchSlice.reducer;
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '.'
import { AlertColor } from '@mui/material';

interface GlobalError {
    isOpen: boolean;
    message: string;
    severity: AlertColor;
}

type GlobalErrorPayload = Omit<GlobalError, 'isOpen'>;

// Define a type for the slice state
interface CoreState {
    isSidebarOpen: boolean;
    globalError: GlobalError;
}

// Define the initial state using that type
const initialState: CoreState = {
    isSidebarOpen: false,
    globalError: {
        isOpen: false,
        message: '',
        severity: 'warning',
    },
};

const coreSlice = createSlice({
    name: 'core',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        toggleSidebar: (state) => {
            state.isSidebarOpen = !state.isSidebarOpen;
        },
        setSidebarOpen: (state, action: PayloadAction<boolean>) => {
            state.isSidebarOpen = action.payload;
        },
        toggleGlobalError: (state) => {
            state.globalError.isOpen = !state.globalError.isOpen;
        },
        setGlobalError: (state, action: PayloadAction<GlobalErrorPayload>) => {
            state.globalError = {
                isOpen: true,
                ...action.payload,
            };
        },
    },
});

export const {
    toggleSidebar,
    setSidebarOpen,
    toggleGlobalError,
    setGlobalError,
} = coreSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectCore = (state: RootState) => state.core;

export default coreSlice.reducer;
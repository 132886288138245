// Provides centralized redux store
// When to put state in store?
// 1. as a hacky way to share state between the persistent AuthLayout and Page components
// 2. when state needs to be lifted up but will be used in many different components or in a deep component, and so it's more annoying to pass the props down to every component (a good example could be floating toolkit)

import { configureStore } from '@reduxjs/toolkit'
import search from './search'
import core from './core'

export const store = configureStore({
    reducer: {
        // core state for general UI
        core,
        // state for search UI
        search,
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch